<template>
  <div class="orderMealSuccess">
    <div class="success-img"><img src="./img/success.png" alt="" /></div>
    <div class="title">你的订单已提交成功～</div>
    <div class="btn">
      <div class="btn-l" @click="toOrder">继续预定</div>
      <div class="btn-r" @click="toMyOrder">去查看</div>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import { mealListUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "orderMealSuccess",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // gloabalCount("", 20, 1);
  },
  methods: {
    toOrder() {
      this.$router.replace({
        name: "orderMeal",
      });
    },
    toMyOrder() {
      this.$router.replace({
        name: "orderMealList",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.orderMealSuccess {
  height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  padding-top: 42px;
  overflow: hidden;
  box-sizing: border-box;
  .success-img {
    width: 320px;
    height: 320px;
    margin: 0 auto 52px;
    img {
      height: 100%;
      height: 100%;
    }
  }
  .title {
    font-size: 32px;
    font-weight: 400;
    color: #666666;
    line-height: 44px;
    text-align: center;
    margin-bottom: 100px;
  }
  .btn {
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    .btn-l,
    .btn-r {
      width: 296px;
      height: 96px;
      border-radius: 48px;
      border: 2px solid #007eff;
      font-size: 32px;
      text-align: center;
      font-weight: 600;
      color: #007eff;
      line-height: 96px;
    }
  }
}
</style>
<style lang="less">
.orderMealSuccess {
}
</style>
